import React from 'react';
import { DidomiButton, DidomiIllustration } from '@didomi/ui-atoms-react';
import { useAuthMethods } from '@didomi/utility-react';

export const UserWithNoOrgMessage = () => {
  const { logout } = useAuthMethods();

  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-white">
      <DidomiIllustration name="non-authorized-page" />
      <div className="mt-s text-center text-body-normal font-sans text-primary-blue-6">
        This profile is not linked to any organization.
        <br />
        If you think this is a mistake, we suggest to contact your organization administrator.
      </div>
      <div className="mt-xxl">
        <DidomiButton size="small" variant="secondary" onClick={() => logout({})}>
          Logout
        </DidomiButton>
      </div>
    </div>
  );
};
