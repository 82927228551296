import { useAuthToken, useAuthMethods, useUserProfile } from '@didomi/utility-react';
import { addAxiosResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/axios/axios-response-interceptors';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { CreateOrganizationInput, CreateOrganizationResponse } from '@types';
import { traceError } from '@utils';

/**
 * Will create a new organization and add the requesting user as the first member.
 * See Admin API src/services/organizations/hooks/add-user-as-member.hook.js
 */
export const useCreateOrganization = (
  options: UseMutationOptions<AxiosResponse, AxiosError, CreateOrganizationInput>,
): UseMutationResult<AxiosResponse, AxiosError, CreateOrganizationInput> => {
  const token = useAuthToken();
  const { logout } = useAuthMethods();
  const [userProfile] = useUserProfile();

  return useMutation(async (createOrganizationData: CreateOrganizationInput) => {
    const axiosWithInterceptors = addAxiosResponseInterceptors(axios, { onExpiredToken: logout, onForbidden: logout });

    const organizationCreationResponse = await axiosWithInterceptors.post<CreateOrganizationResponse>(
      `${CONFIG.environmentConfig.apiBaseUrl}/organizations`,
      createOrganizationData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const { id: organizationId } = organizationCreationResponse.data;

    try {
      await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${CONFIG.environmentConfig.hubspot.portalId}/${CONFIG.environmentConfig.hubspot.formGuid}`,
        {
          fields: [
            {
              objectTypeId: '0-1',
              name: 'email',
              value: userProfile.email,
            },
            {
              objectTypeId: '0-1',
              name: 'company',
              value: createOrganizationData.name,
            },
            {
              objectTypeId: '0-1',
              name: 'self_service_org_id',
              value: organizationId,
            },
            {
              objectTypeId: '0-1',
              name: 'website',
              value: createOrganizationData.website,
            },
          ],
        },
        { params: { hapikey: CONFIG.environmentConfig.hubspot.apiKey } },
      );
    } catch (error) {
      traceError(error);
    }

    return organizationCreationResponse;
  }, options);
};
