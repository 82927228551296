export const traceError = (error: Error) => {
  const detail = {
    error: error,
    dsn: CONFIG.sentryDsn,
    environment: CONFIG.environment,
    release: CONFIG.commitSha,
  };
  const errorEvent = new CustomEvent('on-spa-error', { detail });

  window.dispatchEvent(errorEvent);
};
