import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DidomiHintbox, DidomiTextInputField, DidomiButton, DidomiSwitchField, DidomiLoader } from '@didomi/ui-atoms-react';
import { useSPARouter, useUtility, useSnackbar, useEnvironment } from '@didomi/utility-react';
import { useCreateOrganization, useAssetsUrl } from '@hooks';
// We use the same validator used in the admin-api for consistency
import isUrl from 'validator/es/lib/isURL';

const OrganizationSetupSchema = Yup.object().shape({
  name: Yup.string(),
  website: Yup.string()
    .test('isURL', 'Please enter a valid URL (e.g. https://example.com)', v => !v || isUrl(v))
    .required('Company URL is required'),
  owner: Yup.string().oneOf(['selected'], "Please confirm that you are allowed to act on the website's behalf").required(),
});

export const OrganizationSetupPage = () => {
  const { navigateTo } = useSPARouter();
  const { Organizations } = useUtility();
  const { displaySnackbar } = useSnackbar();
  const { assetsBaseUrl } = useEnvironment();

  const { mutate, isLoading } = useCreateOrganization({
    onSuccess() {
      Organizations.getOrganizations().then(() => {
        navigateTo(`/dashboard`);
      });
    },
    onError() {
      displaySnackbar('There was an error creating your organization. Please try again or use one of our support channels', {
        variant: 'error',
        action: {
          name: 'Close',
          closeInAction: true,
        },
        permanent: true,
      });
    },
  });

  const handleFormSubmit = async ({ name, website }: { name: string; website: string; owner: string }) => {
    mutate({
      name: name || 'My company',
      website,
    });
  };

  const ASSETS_URL = useAssetsUrl();

  return (
    <div className="flex justify-end min-h-full">
      <div className="flex items-stretch w-full flex-col lg:flex-row!">
        <div className="left bg-light-blue lg:w-1/2 flex relative overflow-y-hidden justify-center">
          <div
            id="left-container"
            className="w-full text-center flex flex-col justify-center items-center py-l px-s md:(px-m) lg:(pl-[80px]! pr-0! w-5/6! max-w-[900px] items-start text-left) relative"
          >
            <img src={`${assetsBaseUrl}/global/sphere-pink-blur.png`} className="hidden lg:block absolute w-[500px] h-[500px] bottom-[-250px] left-[-150px]" alt="" />
            <h1 className="h1-catchline-medium 2xl:h1-catchline text-primary-blue-6 mb-xs">There you are!</h1>
            <h3 className="h3 2xl:h1 text-primary-blue-6 mb-m">Let&#39;s start with a compliance report to evaluate your privacy score</h3>
            <DidomiHintbox titleText="What is a compliance report?" variant="white" className="shadow-small-push">
              <p className="whitespace-pre-wrap">
                Our report analyzes key elements impacting your GDPR compliance (privacy policy compliance, cookies checks, etc.), as well as a simple way to track which vendors
                are embedded on your domain, and how they ended up there.
              </p>
            </DidomiHintbox>
          </div>
        </div>
        <div className="right bg-white lg:w-1/2 relative text-center flex flex-col justify-center items-center">
          {isLoading && (
            <div className="w-3/5 flex flex-col justify-center items-center h-full">
              <DidomiLoader />
              <p className="body-big-semibold text-primary-blue-4 mt-xs">Please wait...</p>
              <p className="h3 text-primary-blue-6">We are gathering all the information for your compliance report</p>
            </div>
          )}
          {!isLoading && (
            <Fragment>
              <img
                src={`${ASSETS_URL}/assets/illustrations/guiding_arrow.svg`}
                className="absolute transform rotate-90 scale-50 -top-[24px] lg:(rotate-0 w-[120px] left-[-60px] lg:top-[20vh]) xl:top-[25vh] 2xl:top-[30vh]"
                alt=""
              />
              <div id="right-container" className="pt-l px-s md:(px-0! min-w-[400px] w-[70%] max-w-[450px]) mx-auto h-full flex flex-col justify-center">
                <img src={`${assetsBaseUrl}/global/Run-the-compliance-report.png`} className="min-w-[150px] w-1/3 2xl:w-1/2 mx-auto" alt="" />
                <h3 className="h3 font-bold text-primary-blue-6 mt-xxxs md:mt-s">
                  To run the report, we need
                  <br />
                  your domain information
                </h3>
                <Formik
                  initialValues={{
                    name: '',
                    website: 'https://',
                    owner: 'not-selected',
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={OrganizationSetupSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <DidomiTextInputField className="block mt-s" name="name" label="The name of your company" placeholder="My company"></DidomiTextInputField>
                      <DidomiTextInputField
                        className="block mt-xxxs"
                        name="website"
                        label="The URL of your company's website"
                        placeholder="https://mycompany.com"
                        required
                      ></DidomiTextInputField>
                      <DidomiSwitchField name="owner" className="mt-xxxs text-left" required>
                        I am the owner of the website or I am adding the website on the Didomi platform as a duly authorized representative of its owner.*
                      </DidomiSwitchField>
                      <p className="field-required-text italic mt-xxxs text-left">* Required fields</p>
                      <DidomiButton className="my-s" type="submit" data-cy="submit-btn" data-tracking="self-service-start-compliance-test">
                        Start the compliance test
                      </DidomiButton>
                    </form>
                  )}
                </Formik>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
