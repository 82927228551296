import React, { useEffect } from 'react';
import { OrganizationSetupPage } from '../OrganizationSetup/OrganizationSetup';
import { useUserProfile } from '@didomi/utility-react';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { UserWithNoOrgMessage } from '../UserWithNoOrgMessage/UserWithNoOrgMessage';

const App = () => {
  const [userProfile, isLoading] = useUserProfile();

  useEffect(() => {
    // TODO: Move this logic to the GTM utility and a react version
    window['dataLayer']?.push({
      event: 'product-version',
      product: 'self-service',
      page_path: 'organization-setup',
    });
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  if (!userProfile?.self_register) {
    return <UserWithNoOrgMessage />;
  }

  return <OrganizationSetupPage />;
};

export { App };
